import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";

import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-add-case',
  templateUrl: './add-case.component.html',
  styleUrls: ['./add-case.component.css']
})
export class AddCaseComponent implements OnInit {

  constructor(private route: ActivatedRoute, 
		private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private router: Router) {}

	caseGroup: FormGroup;

	ngOnInit() {
		this.caseGroup = this._formBuilder.group({
			ssn: ['', Validators.required],
			secondarySearch: ['', Validators.required],
		});
	}

	search(){
		if(this._apiService.formgroupHasErrors(this.caseGroup)){
			return;	
		}

		this.router.navigate(["/search-result","000000000","12345"]);
	}

}
