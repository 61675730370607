import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import {ApiService} from "../../services/api.service";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private _router: Router,
    public translate: LanguageService) {}

  recoverGroup: FormGroup;

  ngOnInit() {
  	this.recoverGroup = this._formBuilder.group({
		email: ['', Validators.required],
	});
  }

  sendReset(){
    if(this._apiService.formgroupHasErrors(this.recoverGroup)){
      return;  
    }
  		var data = {"email":this.recoverGroup.get("email").value, "requestingUrl": window.location.href, "operation":"request", locale: this.translate.getlanguage(), "deviceType":1, "resetType":"email"}
    this._apiService.doRequest("password reset", data).subscribe(res => {
      var response = JSON.parse(res["body"]);
      if(response.error){
        this._apiService.showDialog("Error",response.errorMessage);
        return
      }
      this._apiService.showDialog(this.translate.getTranslation("success"),this.translate.getTranslation("resetEmailSent"));
    });
  }

}
