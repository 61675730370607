import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  	this._router.events.subscribe(event => {
		if(event instanceof NavigationEnd){
			var element = document.getElementsByClassName("nav-item");
			for (var i = 0; i < element.length; ++i) {
				element[i].classList.remove("active");
				if(element[i].firstElementChild.getAttribute("routerLink") == event.url){
					element[i].classList.add("active");
				}
				
			}
		}
	});
  }

  logout(){
  	this._router.navigate(["/login"]);
  }

}
