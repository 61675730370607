import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

import { BehaviorSubject } from 'rxjs';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { ApiService } from "./api.service";

import { NotAuthenticatedDialog } from '../components/login/not_authenticated';

@Injectable()
export class AppState {

	constructor(private _apiService: ApiService, public _matDialog: MatDialog, private _router: Router ) { }

	private language = new BehaviorSubject<string>(this.getlanguage());
	currentLanguage = this.language.asObservable();	

	setLanguage(language: string){
		localStorage.setItem("language", language);
		this.language.next(language);
	}

	private getlanguage(){
		if(localStorage.getItem("language") != null && localStorage.getItem("language") != "undefined"){
			return localStorage.getItem("language");
		}else{
			return "en";
		}
	}
}
