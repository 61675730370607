import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'message-dialog',
  templateUrl: './message_dialog.html',
  styleUrls: [ './message_dialog.css' ],
  providers: [LanguageService],
})

export class MessageDialog {

	cancel = "cancel";

  constructor(
    public dialogRef: MatDialogRef<MessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public translate: LanguageService) { }


  close(){
  	this.dialogRef.close();
  }

}