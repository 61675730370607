import { Component } from '@angular/core';

import {AppState} from "./services/app.state";
import {ApiService} from "./services/api.service";
import {LanguageService} from "./services/language.service";

import 'hammerjs';

@Component({
 selector: 'ipay',
  providers: [AppState, ApiService, LanguageService],
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  title = 'iPayOnline';
}
