export var TRANSLATIONS = {
  "acceptBid": {
    "en-us": "Accept Bid",
    "es-es": "Aceptar puja",
  },
  "login": {
    "en-us": "Login",
    "es-es": "Inicia sesión",
  },
  "email": {
    "en-us": "Email",
    "es-es": "Correo electrónico",
  },
  "password": {
    "en-us": "Password",
    "es-es": "Contraseña",
  },
  "passwordsDontMatch": {
    "en-us": "Passwords don't match",
    "es-es": "Las contraseñas no coinciden",
  },
  "lostPassword": {
    "en-us": "Lost password?",
    "es-es": "¿Perdiste contraseña?",
  },
  "register": {
    "en-us": "Register",
    "es-es": "Regístrese",
  },
  "resetPassword": {
    "en-us": "Reset Password",
    "es-es": "Restablecer la contraseña",
  },
  "sendResetPasswordEmail": {
    "en-us": "Send reset password email",
    "es-es": "Enviar restablecer contraseña de correo electrónico",
  },
  "individual": {
    "en-us": "Individual",
    "es-es": "Individual",
  },
  "employer": {
    "en-us": "Employer",
    "es-es": "Empleador",
  },
  "individualType":{
    "en-us": "Individual Type",
    "es-es": "Tipo de individual",
  },
  "ncp":{
    "en-us": "Non-Custodial Parent",
    "es-es": "Padre sin custodia",
  },
  "cp":{
    "en-us": "Custodial Parent",
    "es-es": "Padre de la custodia",
  },
  "both":{
    "en-us": "Both",
    "es-es": "Ambos",
  },
  "accountType":{
    "en-us": "Account Type",
    "es-es": "Tipo de cuenta",
  },
  "firstName": {
    "en-us": "First Name",
    "es-es": "Nombre de pila",
  },
  "lastName": {
    "en-us": "Last Name",
    "es-es": "Apellido",
  },
  "phoneNumber": {
    "en-us": "Phone Number",
    "es-es": "Número de Teléfono",
  },
  "confirmPassword": {
    "en-us": "Confirm Password",
    "es-es": "Confirmar Contraseña",
  },
  "companyName": {
    "en-us": "Company Name",
    "es-es": "Nombre de Empresa",
  },
  "incompleteForm": {
    "en-us": "Incomplete Form",
    "es-es": "Forma incompleta",
  },
  "missing": {
    "en-us": "Missing",
    "es-es": "Desaparecido",
  },
  "locale": {
    "en-us": "Language",
    "es-es": "Idioma",
  },
  "fein": {
    "en-us": "FEIN",
    "es-es": "FEIN",
  },
  "passwordOne": {
    "en-us": "Password",
    "es-es": "Contraseña",
  },
  "passwordTwo": {
    "en-us": "Confirm Password",
    "es-es": "Confirmar Contraseña",
  },
   "close":{
     "en-us": "Close",
     "es-es": "Cerca",
   },
   "error":{
     "en-us": "Error",
     "es-es": "Error",
   },
   "feinNotNine":{
     "en-us": "FEIN is not nine digits",
     "es-es": "FEIN no tiene nueve dígitos",
   },
   "minimumPasswordLength":{
     "en-us": "Minimum password length is 5",
     "es-es": "La longitud mínima de la contraseña es 5",
   },
   "success":{
     "en-us": "Sucess",
     "es-es": "Éxito",
   },
   "passwordresetHasBeenEmailed":{
     "en-us": "Your password reset has been emailed to you",
     "es-es": "Su restablecimiento de contraseña ha sido enviado por correo electrónico a usted",
   },
   "submit":{
     "en-us": "Submit",
     "es-es": "Enviar",
   },
   "resetEmailSent":{
     "en-us": "Check your email for your password reset request",
     "es-es": "Revisa tu correo electrónico para ver tu solicitud de restablecimiento de contraseña",
   }

}
