import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";

import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.css']
})
export class AddPaymentComponent implements OnInit {

   constructor(private route: ActivatedRoute, 
		private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private router: Router) {}

	paymentGroup: FormGroup;

	paymentType = "";
	accountType = "";
	scheduleType = "";
	cases = [];

	ngOnInit() {

	}

	startDate = (d: Date): boolean => {
		if(this.accountType == "bank"){
			var day = d.getDay();
			return day !== 0 && day !== 6 && d.getTime() > new Date().getTime();
		}else{
			return d.getTime() > new Date().getTime() - (1000 * 60 *60 * 24);
		}		
	}

	getCasesLines(){
		return this.cases;
	}

}
