import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'working-dialog',
  templateUrl: './working_dialog.html',
  styleUrls: [ './working_dialog.css' ],
})

export class WorkingDialog {

  constructor(
    public dialogRef: MatDialogRef<WorkingDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  close(){
  	this.dialogRef.close();
  }

}