import {Injectable} from '@angular/core';

declare var CryptoJS: any;

@Injectable()
export class PasswordService {

	hashPassword(password: string){
		return CryptoJS.SHA256(password).toString()	
	}
}
