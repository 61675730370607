import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import {AppState} from "../../services/app.state";

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

	states = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];
  accountGroup: FormGroup;

 constructor(private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private _appState: AppState,
		private _router: Router ) { }

  ngOnInit() {
  	this.accountGroup = this._formBuilder.group({
			firstName: ['', Validators.required],
			email: ['', Validators.required],
			lastName: ['', Validators.required],
			phoneNumber: ['', Validators.required],
			address: ['', Validators.required],
			addressTwo: ['', Validators.required],
			city: ['', Validators.required],
			state: ['', Validators.required],
			zip: ['', Validators.required],
		});
  }

  update(){
		if(this._apiService.formgroupHasErrors(this.accountGroup)){
			return;	
		}

		this._router.navigate(["/dashboard"]);

		// var data = {"email":this.loginGroup.get("email").value, "password":this._passwordService.hashPassword(this.loginGroup.get("password").value)}
		// this._apiService.doRequest("login", data).subscribe(res => {
		// 	var response = JSON.parse(res["body"]);
		// 	if(response.error){
		// 		this._apiService.showDialog("Error",response.errorMessage);
		// 		return
		// 	}
		// 	if(response.authorized){
		// 		localStorage.setItem("session",response.session)

		// 		return
		// 	}
		// 	this._apiService.showDialog("Error","Unknown error occurred");
		// });

	}

}
