import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.css']
})
export class ViewAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
