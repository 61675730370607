import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { MessageDialog } from '../components/message_dialog/message_dialog';
import { WorkingDialog } from "../components/working_dialog/working_dialog";

import {ApiRequest} from "../models/api_request";
import {LanguageService} from "./language.service";
import {Command} from "../models/command";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ApiService {

	constructor(private http:HttpClient, public _matDialog: MatDialog, public translate: LanguageService) {}

	private apiUrl = "https://j96hafh4ak.execute-api.us-west-2.amazonaws.com/iPay-2-Dev";


	doRequest(command: string, data: any) {
		
		return this.http.post(this.apiUrl, this.buildCommand(command, data), httpOptions)
	    .pipe(
	        retry(3),
	        catchError(this.handleError)
	      );
	}

	private buildCommand(stringCommand: string, data: any) {
		var apiRequest = new ApiRequest();
		var command = new Command();
		command.command = stringCommand;
		command.data = data;

		apiRequest.stringJson = JSON.stringify(command);
		apiRequest.userAgent = navigator.userAgent;
		if(localStorage.getItem("session") != null){
			apiRequest.authtoken = localStorage.getItem("session");
		}

		return apiRequest;

	}

	private handleError(error: HttpErrorResponse) {
	  if (error.error instanceof ErrorEvent) {
	    console.error('An error occurred:', error.error.message);
	  } else {
	    console.error(
	      `Backend returned code ${error.status}, ` +
	      `body was: ${error.error}`);
	  }
	  return throwError(
	    'Something bad happened; please try again later.');
	};

	public formgroupHasErrors(formGroup: FormGroup): boolean{

		var hasError = false;
		var errors = [];

		Object.keys(formGroup.controls).forEach(key => {
			if(formGroup.controls[key].errors != null ){
				if(formGroup.controls[key].errors.required){
					errors.push(this.translate.getTranslation(key));
					hasError = true;
				}
			}
		});

		if(hasError){
			this.showDialog(this.translate.getTranslation("incompleteForm"), "<h4 mat-dialog-title>"+this.translate.getTranslation("missing")+":</h4><br>" + errors.join("<br>"));
		}

		return hasError;
	}

	public showDialog(title: string, message: string){
		let dialogRef;
		let data;

		data = {"message": message, "title": title}

		dialogRef = this._matDialog.open(MessageDialog, {
			width: '250px',
			data: data
		});

	}

	public showWorking(){
		let dialogRef;
		let data;

		data = {}

		dialogRef = this._matDialog.open(WorkingDialog, {
			width: '175px',
			data: data
		});
	}

	public closeWorking(){
		this._matDialog.closeAll()
	}

}
