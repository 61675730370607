import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import {AppState} from "../../services/app.state";

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import {ApiService} from "../../services/api.service";
import {PasswordService} from "../../services/password_service";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-register',
  providers: [PasswordService],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerGroup: FormGroup;
  accountType = ""

	constructor(private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private _passwordService: PasswordService,
		private _appState: AppState,
		private _router: Router,
		public translate: LanguageService ) { }

	ngOnInit() {
		this.registerGroup = this._formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			email: ['', Validators.required],
			accountType: ['', Validators.required],
      individualType: [''],
			companyName: [''],
			phoneNumber: ['', Validators.required],
			fein: [''],
			locale: ['', Validators.required],
			passwordOne: ['', Validators.required],
			passwordTwo: ['', Validators.required],
		});

		this.registerGroup.patchValue({"locale":this.translate.getlanguage()})
	}

	register(){
		if(this._apiService.formgroupHasErrors(this.registerGroup)){
			return;
		}

		if(this.registerGroup.get("passwordOne").value != this.registerGroup.get("passwordTwo").value){
			this._apiService.showDialog(this.translate.getTranslation("error"),this.translate.getTranslation("passwordsDontMatch"));
			return;
		}

		if(this.registerGroup.get("accountType").value == "2"){
			if(this.registerGroup.get("accountType").value.length == 0){
				this._apiService.showDialog(this.translate.getTranslation("error"),this.translate.getTranslation("companyName"));
				return;
			}

			if(this.registerGroup.get("fein").value.length != 9){
				this._apiService.showDialog(this.translate.getTranslation("error"),this.translate.getTranslation("feinNotNine"));
				return;
			}
		}

		if(this.registerGroup.get("passwordOne").value.length < 5){
			this._apiService.showDialog(this.translate.getTranslation("error"),this.translate.getTranslation("minimumPasswordLength"));
			return;
		}

		var data = {
			"email":this.registerGroup.get("email").value,
			"password":this._passwordService.hashPassword(this.registerGroup.get("passwordOne").value),
			"firstName":this.registerGroup.get("firstName").value,
			"lastName":this.registerGroup.get("lastName").value,
			"company":this.registerGroup.get("companyName").value,
			"phoneNumber":this.registerGroup.get("phoneNumber").value,
			"accountType":parseInt(this.registerGroup.get("accountType").value),
      "individualType":parseInt(this.registerGroup.get("individualType").value),
			"payorId":this.registerGroup.get("fein").value,
			"deviceType":1, //Web type
			"locale":this.registerGroup.get("locale").value,
		}

		this._apiService.doRequest("register", data).subscribe(res => {
			var response = JSON.parse(res["body"]);
			if(response.error){
				this._apiService.showDialog(this.translate.getTranslation("error"),response.errorMessage);
				return
			}
			if(!response.error){
				localStorage.setItem("session",response.session)
				this._router.navigate(["/dashboard"]);
				return
			}
			this._apiService.showDialog("Error","Unknown error occurred");
		});

	}

	setLanguage(){
		this._appState.setLanguage(this.registerGroup.get("locale").value);
	}

}
