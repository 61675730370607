import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  MatButtonModule, 
	MatFormFieldModule, 
	MatInputModule, 
	MatSelectModule, 
	MatStepperModule, 
	MatDatepickerModule, 
	MatNativeDateModule,
	MatRadioModule,
	MatListModule,
	MatIconModule,
	MatTooltipModule,
	MatAutocompleteModule,
	MatDialogModule,
	MatProgressSpinnerModule,
	MatSlideToggleModule,
	MatCheckboxModule,
	MatTableModule,
	MatSortModule } from '@angular/material';

@NgModule({
  imports: [ 
	  MatButtonModule, 
	  MatFormFieldModule, 
	  MatInputModule, 
	  MatSelectModule, 
	  MatStepperModule, 
	  MatDatepickerModule, 
	  MatNativeDateModule,
	  MatRadioModule,
	  MatListModule,
	  MatIconModule,
	  MatTooltipModule,
	  MatAutocompleteModule,
	  MatDialogModule,
	  MatProgressSpinnerModule,
	  MatSlideToggleModule,
	  MatCheckboxModule,
	  MatTableModule,
	  MatSortModule
  ],
  exports: [ 
	  MatButtonModule, 
	  MatFormFieldModule, 
	  MatInputModule, 
	  MatSelectModule, 
	  MatStepperModule, 
	  MatDatepickerModule, 
	  MatNativeDateModule,
	  MatRadioModule,
	  MatListModule,
	  MatIconModule,
	  MatTooltipModule,
	  MatAutocompleteModule,
	  MatDialogModule,
	  MatProgressSpinnerModule,
	  MatSlideToggleModule,
	  MatCheckboxModule,
	  MatTableModule,
	  MatSortModule
  ],
})
export class MaterialModule { }