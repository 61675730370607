import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import {AppState} from "../../services/app.state";

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import {ApiService} from "../../services/api.service";
import {PasswordService} from "../../services/password_service";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-login',
  providers: [PasswordService],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	loginGroup: FormGroup;

	constructor(private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private _passwordService: PasswordService,
		private _appState: AppState,
		private _router: Router, 
		public translate: LanguageService ) { }

	ngOnInit() {
		this.loginGroup = this._formBuilder.group({
			email: ['', Validators.required],
			password: ['', Validators.required],
			locale: ['', Validators.required],
		});

		this.loginGroup.patchValue({"locale":this.translate.getlanguage()})
	}

	login(){
		if(this._apiService.formgroupHasErrors(this.loginGroup)){
			return;	
		}

		// Device type 1 is web type
		var data = {
			"email":this.loginGroup.get("email").value, 
			"password":this._passwordService.hashPassword(this.loginGroup.get("password").value), 
			"locale":this.loginGroup.get("locale").value,
			"deviceType":1}
		this._apiService.doRequest("login", data).subscribe(res => {
			var response = JSON.parse(res["body"]);
			if(response.error){
				this._apiService.showDialog("Error",response.errorMessage);
				return
			}
			if(response.authorized){
				localStorage.setItem("session",response.session)
				this._router.navigate(["/dashboard"]);
				return
			}
			this._apiService.showDialog("Error","Unknown error occurred");
		});

	}

	setLanguage(){
		this._appState.setLanguage(this.loginGroup.get("locale").value);
	}

}
