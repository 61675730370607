import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import {ApiService} from "../../services/api.service";
import {LanguageService} from "../../services/language.service";
import {PasswordService} from "../../services/password_service";

@Component({
  selector: 'app-password-recovery-do-reset',
  providers: [PasswordService],
  templateUrl: './password-recovery.component-do-reset.html',
  styleUrls: ['./password-recovery.component-do-reset.css']
})
export class PasswordRecoveryComponentDoReset implements OnInit {

  constructor(
    private route: ActivatedRoute, 
    private _formBuilder: FormBuilder,
		private _apiService: ApiService,
    private _passwordService: PasswordService,
		private _router: Router,
    public translate: LanguageService ) { }

  recoverGroup: FormGroup;
  id = "";

  ngOnInit() {
  	this.recoverGroup = this._formBuilder.group({
  		passwordOne: ['', Validators.required],
      passwordTwo: ['', Validators.required],
  	});

    this.route.params.subscribe( params => {
      this.id = params["id"];
    });
  }

  doReset(){
    if(this._apiService.formgroupHasErrors(this.recoverGroup)){
      return;  
    }

    if(this.recoverGroup.get("passwordOne").value != this.recoverGroup.get("passwordTwo").value){
      this._apiService.showDialog(this.translate.getTranslation("error"),this.translate.getTranslation("passwordsDontMatch"));
      return;
    }

    if(this.recoverGroup.get("passwordOne").value.length < 5){
      this._apiService.showDialog(this.translate.getTranslation("error"),this.translate.getTranslation("minimumPasswordLength"));
      return;  
    }

  		var data = {"resetKey": this.id, "operation":"perform", locale: this.translate.getlanguage(), "password":this._passwordService.hashPassword(this.recoverGroup.get("passwordOne").value)};
      this._apiService.doRequest("email reset password", data).subscribe(res => {
        var response = JSON.parse(res["body"]);
        console.log(response)
        if(response.error){
          this._apiService.showDialog("Error",response.errorMessage);
          return
        }
        this._apiService.showDialog(this.translate.getTranslation("success"),this.translate.getTranslation("passwordresetHasBeenEmailed"));
      });
  }

}
