import { Routes } from '@angular/router';

import { AuthGuard } from "./auth_guard";

import { NavbarComponent } from './components/navbar/navbar.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ContactComponent } from './components/contact/contact.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TermsComponent } from './components/terms/terms.component';
import { AccountComponent } from './components/account/account.component';
import { AddAccountComponent } from './components/add-account/add-account.component';
import { AddCaseComponent } from './components/add-case/add-case.component';
import { AddPaymentComponent } from './components/add-payment/add-payment.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { PasswordRecoveryComponentDoReset } from './components/password-recovery-do-reset/password-recovery.component-do-reset';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { ManageAccountComponent } from './components/manage-account/manage-account.component';
import { ManageCasesComponent } from './components/manage-cases/manage-cases.component';
import { ManagePaymentsComponent } from './components/manage-payments/manage-payments.component';
import { CaseSearchResultComponent } from './components/case-search-result/case-search-result.component';
import { PerformPasswordResetComponent } from './components/perform-password-reset/perform-password-reset.component';
import { ViewAccountComponent } from './components/view-account/view-account.component';
import { ActivitiesComponent } from './components/activities/activities.component';


export const ROUTES: Routes = [
  
  { path: '',      component: ActivitiesComponent, canActivate: [AuthGuard] },
  { path: 'dashboard',  component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'login',  component: LoginComponent },
  { path: 'register',  component: RegisterComponent },
  { path: 'terms',  component: TermsComponent },
  { path: 'contact',  component: ContactComponent },
  { path: 'forgot',  component: PasswordRecoveryComponent },
  { path: 'emailpasswordreset/:id',  component: PasswordRecoveryComponentDoReset },
  { path: 'edit-account',  component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'add-account/:id',  component: AddAccountComponent, canActivate: [AuthGuard] },
  { path: 'add-case',  component: AddCaseComponent, canActivate: [AuthGuard] },
  { path: 'add-payment/:id',  component: AddPaymentComponent, canActivate: [AuthGuard] },
  { path: 'add-user/:id',  component: AddUserComponent, canActivate: [AuthGuard] },
  { path: 'manage-account',  component: ManageAccountComponent, canActivate: [AuthGuard] },
  { path: 'manage-case',  component: ManageCasesComponent, canActivate: [AuthGuard] },
  { path: 'manage-payment',  component: ManagePaymentsComponent, canActivate: [AuthGuard] },
  { path: 'manage-user',  component: ManageUserComponent, canActivate: [AuthGuard] },
  { path: 'payment/:id',  component: PaymentDetailsComponent, canActivate: [AuthGuard] },
  { path: 'password-reset/:id',  component: PerformPasswordResetComponent, canActivate: [AuthGuard] },
  { path: 'view-account',  component: ViewAccountComponent, canActivate: [AuthGuard] },
  { path: 'activities',  component: ActivitiesComponent, canActivate: [AuthGuard] },
  { path: '**', component: ActivitiesComponent, canActivate: [AuthGuard] },
  
];
