import {Injectable} from '@angular/core';

import {TRANSLATIONS} from "../data/translations";


@Injectable()
export class LanguageService {
	getTranslation(key: string) {
		if(!(key in TRANSLATIONS)){
			console.log("Missed translation key: " + key);
		}
		return TRANSLATIONS[key][this.getlanguage()];
	}

	getlanguage(){
		if(localStorage.getItem("language") != null && localStorage.getItem("language") != "undefined"){
			return localStorage.getItem("language");
		}else{
			return "en-us";
		}
	}

	setSelectedLanguage(language: string){
		localStorage.setItem("language", language);
	}

}
