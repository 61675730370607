import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";

import {ApiService} from "../../services/api.service";

@Component({
	selector: 'app-add-user',
	templateUrl: './add-user.component.html',
	styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

	constructor(private route: ActivatedRoute, 
		private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private router: Router) {}

	id = 0;
	type = "";
	authUserGroup: FormGroup;

	ngOnInit() {
		this.authUserGroup = this._formBuilder.group({
			firstName: ['', Validators.required],
			middleName: [''],
			lastName: ['', Validators.required],
			phoneNumber: ['', Validators.required],
			emailAddress: ['', Validators.required],
			confirmEmailAddress: ['', Validators.required],
			passwordOne: ['', Validators.required],
			passwordTwo: ['', Validators.required],
		});

		this.route.params.subscribe( params => {
			this.id = parseInt(params["id"]);
			if(this.id == 0){
				this.type = "Add";
			}else{
				this.type = "Edit";
			}
		});
	}

	addUser(){
		if(this._apiService.formgroupHasErrors(this.authUserGroup)){
			return;	
		}
	}

}
