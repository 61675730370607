import { NgModule }         from '@angular/core';
import { BrowserModule }    from '@angular/platform-browser';
import { AppComponent }     from './app.component';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';

import { ROUTES } from './app.routes';

import { MessageDialog } from './components/message_dialog/message_dialog';
import { WorkingDialog } from './components/working_dialog/working_dialog';
import { NotAuthenticatedDialog } from './components/login/not_authenticated';

import { AuthGuard } from "./auth_guard";
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ContactComponent } from './components/contact/contact.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TermsComponent } from './components/terms/terms.component';
import { AccountComponent } from './components/account/account.component';
import { AddAccountComponent } from './components/add-account/add-account.component';
import { AddCaseComponent } from './components/add-case/add-case.component';
import { AddPaymentComponent } from './components/add-payment/add-payment.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { PasswordRecoveryComponentDoReset } from './components/password-recovery-do-reset/password-recovery.component-do-reset';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { ManageAccountComponent } from './components/manage-account/manage-account.component';
import { ManageCasesComponent } from './components/manage-cases/manage-cases.component';
import { ManagePaymentsComponent } from './components/manage-payments/manage-payments.component';
import { CaseSearchResultComponent } from './components/case-search-result/case-search-result.component';
import { PerformPasswordResetComponent } from './components/perform-password-reset/perform-password-reset.component';
import { ViewAccountComponent } from './components/view-account/view-account.component';
import { ActivitiesComponent } from './components/activities/activities.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ContactComponent,
    DashboardComponent,
    TermsComponent,
    AccountComponent,
    AddAccountComponent,
    AddCaseComponent,
    AddPaymentComponent,
    PasswordRecoveryComponent,
    PasswordRecoveryComponentDoReset,
    PaymentDetailsComponent,
    NavbarComponent,
    HeaderComponent,
    MessageDialog,
    WorkingDialog,
    NotAuthenticatedDialog,
    AddUserComponent,
    ManageUserComponent,
    ManageAccountComponent,
    ManageCasesComponent,
    ManagePaymentsComponent,
    CaseSearchResultComponent,
    PerformPasswordResetComponent,
    ViewAccountComponent,
    ActivitiesComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'iPayOnline' }),
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [ 
      MessageDialog, 
      WorkingDialog, 
      NotAuthenticatedDialog,
      ],
})
export class AppModule { }
