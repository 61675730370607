import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";

import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {

  constructor(private route: ActivatedRoute, 
		private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private router: Router) {}

	id = 0;
	operation = "";
	accountType = "";
	confirmAccountNumber = "";
	bankAccountGroup: FormGroup;
	creditCardGroup: FormGroup;

	ngOnInit() {
		this.bankAccountGroup = this._formBuilder.group({
			accountName: ['', Validators.required],
			accountType: ['', Validators.required],
			routingNumber: ['', Validators.required],
			accountNumber: ['', Validators.required],
			confirmAccountNumber: ['', Validators.required],
		});

		this.creditCardGroup = this._formBuilder.group({
			accountName: ['', Validators.required],
			cardNumber: ['', Validators.required],
			cvv: ['', Validators.required],
			expiration: ['', Validators.required],
			cardHolderName: ['', Validators.required],
		});

		this.route.params.subscribe( params => {
			this.id = parseInt(params["id"]);
			if(this.id == 0){
				this.operation = "Add";
			}else{
				this.operation = "Edit";
			}
		});
	}

	addAccount(type: string){
		if(type == "bank"){
			if(this._apiService.formgroupHasErrors(this.bankAccountGroup)){
				return;	
			}
		}else{
			if(this._apiService.formgroupHasErrors(this.creditCardGroup)){
				return;	
			}
		}

		this.router.navigate(["/dashboard"]);
	}

}
