import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-search-result',
  templateUrl: './case-search-result.component.html',
  styleUrls: ['./case-search-result.component.css']
})
export class CaseSearchResultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
