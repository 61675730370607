import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-perform-password-reset',
  templateUrl: './perform-password-reset.component.html',
  styleUrls: ['./perform-password-reset.component.css']
})
export class PerformPasswordResetComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder,
		private _apiService: ApiService,
		private _router: Router ) { }

  resetGroup: FormGroup;

  ngOnInit() {
  	this.resetGroup = this._formBuilder.group({
		passwordOne: ['', Validators.required],
		passwordTwo: ['', Validators.required],
	});
  }

  reset(){
  	if(this._apiService.formgroupHasErrors(this.resetGroup)){
  		return;	
  	}
  }

}
